const moment = require('moment-timezone');
const { stripTimeAndAppendDate } = require('./time');

const breakDownPrizes = primaryPrizePages =>
  primaryPrizePages.reduce(
    (object, currentPrize) => {
      if (currentPrize.prizeType === 'grand_prize') {
        object.grandPrizes.push(currentPrize);
      }
      if (currentPrize.prizeType === 'early_bird_prize') {
        object.earlyBirdPrizes.push(currentPrize);
      }
      if (currentPrize.prizeType === 'bonus_prize') {
        object.bonusPrizes.push(currentPrize);
      }
      return object;
    },
    { grandPrizes: [], earlyBirdPrizes: [], bonusPrizes: [] }
  );

// Revisit this for correctness
const sortByOrderIndex = primaryPrizePages => {
  primaryPrizePages.sort((current, next) => {
    let comparison = 0;
    if (current.displayOrderIndex > next.displayOrderIndex) {
      comparison = 1;
    }
    if (current.displayOrderIndex < next.displayOrderIndex) {
      comparison = -1;
    }
    return comparison;
  });
};

const splitCategoryBlocksByBreak = categoryBlocks => {
  const prizeGroups = [];
  if (!categoryBlocks) {
    return prizeGroups;
  }
  categoryBlocks.reduce((accumulator, currentItem, index) => {
    if (index === categoryBlocks.length - 1) {
      accumulator.push(currentItem);
      prizeGroups.push(accumulator);
      return [];
    }
    if (currentItem.blockType === 'break_block') {
      prizeGroups.push(accumulator);
      return [];
    }
    accumulator.push(currentItem);
    return accumulator;
  }, []);
  return prizeGroups;
};

const splitNormalAndCashBlocks = prizeGroup =>
  prizeGroup.reduce(
    (object, currentItem) => {
      if (currentItem.blockType === 'cash_prize_block') {
        object.cashPrizeBlock.push(currentItem);
        return object;
      }
      object.normalPrizeBlock.push(currentItem);
      return object;
    },
    { normalPrizeBlock: [], cashPrizeBlock: [] }
  );

const getIncludedAddOnPages = addOnPages => addOnPages.filter(pageData => pageData.included);

const getCurrentPrizeWinners = (_primaryPrizePages, _addOnPages, _winnersPage) => {
  const hasWinnerField = primaryPrize =>
    primaryPrize.winnerFirstName || primaryPrize.winnerLastName || primaryPrize.winnerTicketNumber;

  const primaryPrizeWithWinners =
    _primaryPrizePages && _primaryPrizePages.filter(primaryPrize => hasWinnerField(primaryPrize));

  const grandWinners =
    primaryPrizeWithWinners &&
    primaryPrizeWithWinners.filter(
      primaryPrize => hasWinnerField(primaryPrize) && primaryPrize.prizeType === 'grand_prize'
    );

  const otherWinners =
    primaryPrizeWithWinners &&
    primaryPrizeWithWinners.filter(
      primaryPrize => hasWinnerField(primaryPrize) && primaryPrize.prizeType !== 'grand_prize'
    );

  const addOnWinners =
    _addOnPages &&
    _addOnPages.filter(addOn => hasWinnerField(addOn) && addOn.addOnType === 'fifty_fifty');

  // if we have any primiary prize winners return those winners.
  if (grandWinners || otherWinners || addOnWinners) {
    const winners = {
      grandWinners,
      addOnWinners,
      otherWinners
    };
    return winners;
  }

  // if we have any cash calendar winners we still want to generate the /winners page and footer link so return an empty object.
  if (_winnersPage && _winnersPage.calendarWinners && _winnersPage.calendarWinners.length) {
    return {};
  }

  // If we have no primary prize winners OR any cash calendar winners we will not generate a /winners page, as we have no winners to display.
  return false;
};

const getLastExpiredTimer = (timers, timeZone) => {
  const timersBeforeCurrent =
    timers &&
    timers.length > 0 &&
    timers
      .filter(({ jackpotValue, time, date }) => jackpotValue && time && date) // remove once validation is in place on lottogen
      .map(({ jackpotValue, time, date }) => ({
        value: jackpotValue,
        time: stripTimeAndAppendDate(time, date, timeZone).toISOString()
      }))
      .sort((a, b) => moment(a.time).tz(timeZone) - moment(b.time).tz(timeZone))
      .filter(({ time }) =>
        moment(time)
          .tz(timeZone)
          .isBefore(moment().tz(timeZone))
      );

  return timersBeforeCurrent && timersBeforeCurrent.length > 0 && timersBeforeCurrent.pop();
};

module.exports = {
  breakDownPrizes,
  sortByOrderIndex,
  splitCategoryBlocksByBreak,
  splitNormalAndCashBlocks,
  getCurrentPrizeWinners,
  getLastExpiredTimer,
  getIncludedAddOnPages
};
