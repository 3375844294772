// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import CategoryPrizeTile from 'marketing/components/CategoryPrizeTile';
import CategoryBlockCarousel from 'marketing/components/CategoryBlockCarousel';

// Utils
import { splitNormalAndCashBlocks } from 'utils/prizes';

const containerClass = 'category-prize__container l-padding-top';

const containerFlexClass = row => {
  switch (row.length) {
    case 2:
      return 'category-prize__container--2up';
    case 3:
      return 'category-prize__container--3up';
    case 4:
      return 'category-prize__container--4up';
    case 5:
      return 'category-prize__container--5plus';
    default:
      return '';
  }
};

const generatePrizeTiles = row =>
  row.map(({ blockType, heading, subheading, prizeImage, supplierName, grouping }, prizeIndex) => (
    <CategoryPrizeTile
      blockType={blockType}
      heading={heading}
      subHeading={subheading}
      prizeImage={prizeImage}
      supplierName={supplierName}
      grouping={
        prizeIndex !== row.length - 1 || blockType === 'cash_prize_block' ? grouping : undefined
      }
      key={heading}
    />
  ));

class CategoryBlockRow extends PureComponent {
  state = {
    hasCarousel: false,
    normalPrizeBlock: [],
    cashPrizeBlock: []
  };

  componentDidMount() {
    const { normalPrizeBlock, cashPrizeBlock } = splitNormalAndCashBlocks(this.props.prizes);
    const hasCarousel = window.innerWidth > 799 && normalPrizeBlock && normalPrizeBlock.length > 3;
    if (
      normalPrizeBlock &&
      normalPrizeBlock.length > 0 &&
      cashPrizeBlock &&
      cashPrizeBlock.length > 0
    ) {
      cashPrizeBlock[0].grouping = normalPrizeBlock[normalPrizeBlock.length - 1].grouping;
    }

    this.setState({
      normalPrizeBlock,
      cashPrizeBlock,
      hasCarousel
    });
  }

  render() {
    const { hasCarousel, normalPrizeBlock, cashPrizeBlock } = this.state;
    return (
      <>
        {hasCarousel ? (
          <div className="category-prize__container l-padding-top">
            <CategoryBlockCarousel prizes={normalPrizeBlock} />
          </div>
        ) : (
          <div className={classnames(containerClass, containerFlexClass(normalPrizeBlock))}>
            {generatePrizeTiles(normalPrizeBlock)}
          </div>
        )}

        <div className={classnames(containerClass, containerFlexClass(cashPrizeBlock))}>
          {generatePrizeTiles(cashPrizeBlock)}
        </div>
      </>
    );
  }
}

CategoryBlockRow.propTypes = {
  prizes: PropTypes.arrayOf(PropTypes.objectOf({})).isRequired
};

export default CategoryBlockRow;
