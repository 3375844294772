// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import CategoryPrizeTile from 'marketing/components/CategoryPrizeTile';

const CategoryBlockTile = (prize, index, prizes) => {
  const { grouping, subheading, ...rest } = prize;
  const newGrouping = index !== prizes.length - 1 && grouping;

  return (
    <div className="swiper-slide" key={prize.heading}>
      <CategoryPrizeTile {...rest} subHeading={subheading} grouping={newGrouping} />
    </div>
  );
};

CategoryBlockTile.propTypes = {
  prize: PropTypes.objectOf({}).isRequired,
  index: PropTypes.number.isRequired,
  prizes: PropTypes.arrayOf(PropTypes.objectOf({})).isRequired
};

export default CategoryBlockTile;
