// Libraries
import React from 'react';
import classnames from 'classnames';

// Components
import LayoutContainer from 'marketing/containers/LayoutContainer';
import Hero from 'marketing/components/Hero';
import CategoryPrizeGrid from 'marketing/components/CategoryPrizeGrid';
import SupplierBlock from 'marketing/components/SupplierBlock';
import CategoryPrizeTile from 'marketing/components/CategoryPrizeTile';

// Utils
import { splitCategoryBlocksByBreak } from 'utils/prizes';

// Styles
import { layoutContainer } from './styles.module.css';

export default data => {
  const {
    heading,
    subheading,
    heroImage,
    featurePrizeHeading,
    featurePrizeSubheading,
    featurePrizeImage,
    featurePrizeSupplierLogo,
    categoryBlocks,
    singleSupplierLogo,
    singleSupplierText
  } = data.pageContext.data;

  const prizeGroups = splitCategoryBlocksByBreak(categoryBlocks);

  return (
    <LayoutContainer includeOrderTicketsCta includeWinnersLink={data.pageContext.winnersLink}>
      {(subheading || heading || heroImage) && (
        <Hero subHeading={subheading} mainHeading={heading} backgroundUrl={heroImage} />
      )}

      <div className="u-text-align-center theme-background-primary l-padding-bottom">
        {(featurePrizeHeading || featurePrizeSubheading || featurePrizeImage) && (
          <div className={classnames(layoutContainer, 'l-padding-top')}>
            <CategoryPrizeTile
              heading={featurePrizeHeading}
              subHeading={featurePrizeSubheading}
              prizeImage={featurePrizeImage}
              supplierLogo={featurePrizeSupplierLogo}
              featured
            />
          </div>
        )}

        {prizeGroups && prizeGroups.length > 0 && (
          <div className={layoutContainer}>
            <CategoryPrizeGrid prizeGroups={prizeGroups} />
          </div>
        )}
      </div>

      {(singleSupplierLogo || singleSupplierText) && (
        <div className={classnames(layoutContainer, 'l-padding u-text-align-center')}>
          <div className="l-col-8 l-col-offset-2">
            <SupplierBlock logo={singleSupplierLogo} text={singleSupplierText} />
          </div>
        </div>
      )}
    </LayoutContainer>
  );
};
