// Libraries
import React from 'react';
import PropType from 'prop-types';

// Components
import Swiper from 'react-id-swiper';
import CategoryBlockTile from 'marketing/components/CategoryBlockTile';

const CategoryBlockCarousel = ({ prizes }) => {
  const params = {
    slidesPerView: 3,
    spaceBetween: 30,
    freeMode: true,
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true,
      clickable: true,
      renderBullet(index, className) {
        return `<span class="theme-background-secondary ${className}"></span>`;
      }
    },
    breakpoints: {
      940: {
        slidesPerView: 2,
        spaceBetween: 20
      }
    }
  };

  return <Swiper {...params}>{prizes.map(CategoryBlockTile)}</Swiper>;
};

CategoryBlockCarousel.propTypes = {
  prizes: PropType.arrayOf([]).isRequired
};

export default CategoryBlockCarousel;
