// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import NavBarContainer from 'marketing/containers/NavBarContainer';

class Navigation extends React.Component {
  // TODO: Break apart and clean up this function, most of this doesn't belong in componentDidMount
  componentDidMount() {
    const { hasNotification, isHomePage } = this.props;

    // TODO check if <Notification /> has been passed as a child. To avoid padding hasNotification prop.
    const animationController = document.querySelector('#animation-controller');
    const showButtonTriggerPoint = 90;
    const showLogoTriggerPoint = 320;
    // TODO: replace with function instead of let
    let fixedNavTriggerPoint = 2;

    // If we are on the homepage set correct trigger point, accouting for notification (notification height = 73px)
    if (isHomePage) {
      fixedNavTriggerPoint = 600;

      if (hasNotification) {
        fixedNavTriggerPoint += 73;
      }
    }

    function throttle(fn, wait) {
      let time = Date.now();
      return () => {
        if (time + wait - Date.now() < 0) {
          fn();
          time = Date.now();
        }
      };
    }

    const toggleClasses = (addList, removeList) => {
      const classes = animationController.classList;
      addList.forEach(item => classes.add(item));
      removeList.forEach(item => classes.remove(item));
    };

    const handleAnimation = scrollTop => {
      if (scrollTop <= showButtonTriggerPoint) {
        toggleClasses([], ['nav-is-fixed', 'nav-show-button', 'nav-show-logo']);
      } else if (scrollTop <= showLogoTriggerPoint && scrollTop > showButtonTriggerPoint) {
        toggleClasses(['nav-show-button'], ['nav-is-fixed', 'nav-show-logo']);
      } else if (scrollTop > showLogoTriggerPoint && scrollTop <= fixedNavTriggerPoint) {
        toggleClasses(['nav-show-button', 'nav-show-logo'], ['nav-is-fixed']);
      } else {
        toggleClasses(['nav-show-button', 'nav-show-logo', 'nav-is-fixed'], []);
      }
    };

    function headerScroller() {
      const scrollTop = window.pageYOffset || document.body.scrollTop;

      if (isHomePage) {
        handleAnimation(scrollTop);
      } else if (scrollTop <= fixedNavTriggerPoint) {
        // FixNav for non homepage pages (add/remove class)
        toggleClasses([], ['nav-is-fixed']);
      } else {
        toggleClasses(['nav-is-fixed'], []);
      }
    }

    window.onscroll = throttle(headerScroller, 20);
  }

  render() {
    const { children, hasNotification, isHomePage } = this.props;

    return (
      <>
        {isHomePage ? (
          <div
            id="animation-controller"
            className="hero-animation has-minimal-nav u-mobile-nav-space"
          >
            <div
              className={`hero-animation__hero-container--placeholder
                  ${hasNotification && ' has-notification'}`}
            />
            <div
              className={`hero-animation__hero-container
                  ${hasNotification && ' has-notification'}`}
            >
              {children}
            </div>
            <div className="hero-animation__nav-container u-z-index-20">
              <NavBarContainer />
            </div>
          </div>
        ) : (
          <div id="animation-controller" className="hero-animation u-z-index-20 u-mobile-nav-space">
            <NavBarContainer />
          </div>
        )}
      </>
    );
  }
}

Navigation.propTypes = {
  hasNotification: PropTypes.bool,
  isHomePage: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element)
};

Navigation.defaultProps = {
  hasNotification: false,
  isHomePage: false,
  children: []
};

export default Navigation;
