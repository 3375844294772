// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import CategoryBlockRow from 'marketing/components/CategoryBlockRow';

const CategoryPrizeGrid = ({ prizeGroups }) =>
  prizeGroups.map(row => <CategoryBlockRow prizes={row} />);

CategoryPrizeGrid.propTypes = {
  prizeGroups: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        blockType: PropTypes.string,
        grouping: PropTypes.string,
        heading: PropTypes.string,
        prizeImage: PropTypes.string,
        subheading: PropTypes.string,
        supplierName: PropTypes.string
      })
    )
  )
};

CategoryPrizeGrid.defaultProps = {
  prizeGroups: []
};

export default CategoryPrizeGrid;
