// Libraries
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Data
import data from 'data/data.json';

// Components
import Banner from 'marketing/components/Banner';

// Redux
import { toggleOrderModal } from 'ducks/modals/actions';

class NotificationContainer extends PureComponent {
  
  render () {
    const { message, linkText, toggleOrderModalState, disableGlobalBanner } = this.props
    const {
      notificationBanner: {
        displayBanner,
        bannerMessage,
        orderTicketLinkText,
      } = {},
    } = data.raffle;

    if (message || linkText) {
      return (
        <Banner
          message={message}
          linkText={linkText}
          toggleOrderModalState={toggleOrderModalState}
        />
      );
    }

    if (displayBanner && !disableGlobalBanner) {
      return (
        <Banner
          message={bannerMessage}
          linkText={orderTicketLinkText}
          toggleOrderModalState={toggleOrderModalState}
        />
      );
    }

    return false;
  };
};

NotificationContainer.propTypes = {
  global: PropTypes.bool,
  message: PropTypes.string,
  linkText: PropTypes.string,
};

const mapDispatchToProps = {
  toggleOrderModalState: () => toggleOrderModal()
};

export default connect(
  null,
  mapDispatchToProps
)(NotificationContainer);
