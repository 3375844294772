// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const CategoryPrizeTile = ({
  blockType,
  heading,
  subHeading,
  prizeImage,
  grouping,
  supplierName,
  supplierLogo,
  featured
}) => {
  if (blockType === 'prize_block' || featured) {
    const classes = classnames('category-prize', { 'category-prize--large': featured }, grouping);

    return (
      <div className={classes}>
        <div className="category-prize__img-wrapper">
          {prizeImage && <img className="category-prize__img" src={prizeImage} alt="Prize" />}
        </div>

        <div className="category-prize__content">
          <div className="theme-color-primary">
            {heading && (
              <p className="category-prize__heading category-prize__heading--main h2 alt">
                {heading}
              </p>
            )}

            {subHeading && (
              <p className="category-prize__heading category-prize__heading--sub h4">
                {subHeading}
              </p>
            )}
          </div>

          {supplierLogo && (
            <img className="category-prize__supplier-img" src={supplierLogo} alt="" />
          )}
          {supplierName && <p className="category-prize__description">{supplierName}</p>}
        </div>
      </div>
    );
  }
  return (
    <div className="category-prize category-prize--cash">
      {heading && (
        <p className="h3 alt theme-color-primary">
          {`${grouping === 'and_block' ? 'And' : 'Or'} ${heading}`}
        </p>
      )}
    </div>
  );
};

CategoryPrizeTile.propTypes = {
  blockType: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  prizeImage: PropTypes.string,
  supplierName: PropTypes.string,
  supplierLogo: PropTypes.string,
  grouping: PropTypes.string,
  featured: PropTypes.bool
};

CategoryPrizeTile.defaultProps = {
  subHeading: '',
  prizeImage: '',
  supplierName: '',
  grouping: '',
  supplierLogo: '',
  featured: false
};

export default CategoryPrizeTile;
