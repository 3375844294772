// Libraries
import React from 'react';

// Data
import data from 'data/data.json';

// Styling
import { container } from './styles.module.css';

const FooterText = React.memo(() => {
  const { globalModule: { footerText } = {} } = data.raffle;

  return (
    <div className={container}>
      <div dangerouslySetInnerHTML={{ __html: footerText }} />
    </div>
  );
});

export default FooterText;
