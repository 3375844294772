// Libraries
import React from 'react';
import { Helmet } from 'react-helmet';

// Data
import data from 'data/data.json';

// Contants
const FONT_URL_BASE = 'https://fonts.googleapis.com/css?family=Material+Icons|Open+Sans:400,600|';

const {
  name,
  description,
  locale,
  appearance: { headingTypeface, primaryColour, buttonColour, notificationBannerColour } = {}
} = data.raffle;

const defaultAppearance = {
  primaryColour: '#337ab7',
  buttonColour: '#ced6a4',
  notificationBannerColour: '#262629',
  headingTypeface: 'Montserrat'
};

const seo = {
  locale: 'en',
  title: 'Lottery',
  description: 'Kinsmen Lottery Site',
  themeColor: '#008f68'
};

const typeFace = headingTypeface || defaultAppearance.headingTypeface;

const typeFaceLink = `${FONT_URL_BASE}${typeFace && typeFace.replace(/\s/g, '+')}:700`;

const customStyles = `
  .theme-color-primary, .wysiwyg h5 {
    color: ${primaryColour || defaultAppearance.primaryColour};
  }
  .theme-color-secondary {
    color: ${buttonColour || defaultAppearance.buttonColour};
  }
  .theme-color-tertiary {
    color: ${notificationBannerColour || defaultAppearance.notificationBannerColour};
  }
  .theme-background-primary {
    background-color: ${primaryColour || defaultAppearance.primaryColour};
  }
  .theme-background-secondary {
    background-color: ${buttonColour || defaultAppearance.buttonColour};
  }
  .theme-background-tertiary {
    background-color: ${notificationBannerColour || defaultAppearance.notificationBannerColour};
  }
  .theme-border-secondary {
    border: 2px solid ${buttonColour || defaultAppearance.buttonColour}
  }
  .lds-spinner div:after {
    background-color: ${primaryColour || defaultAppearance.primaryColour};
  }
  .alt {
    font-family: ${typeFace};
  }
  .headingFont {
    font-family: ${typeFace};
  }
`;

// TODO: find a better way to include, or at least rename component
const Head = () => (
  <>
    <Helmet>
      <title>{name || seo.title}</title>
      <html lang={locale || seo.locale} />
      <meta name="description" content={description || seo.description} />
      <meta name="theme-color" content={seo.themeColor} />
    </Helmet>
    {/* <!-- TODO look into self hosting smaller icon font set or create svg sprite --> */}
    <link href={typeFaceLink} rel="stylesheet" />

    <style dangerouslySetInnerHTML={{ __html: customStyles }} />
  </>
);

export default Head;
