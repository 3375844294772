// Make this a container, pass data/functions to Dropdown
// Libraries
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Components
import Dropdown from 'marketing/components/Dropdown';
import NavBar from 'marketing/components/NavBar';
import NavItem from 'marketing/components/NavItem';

// Redux
import { toggleMobileMenu } from 'ducks/menu/actions';

// Data
import data from 'data/data.json';

// Utility
import { breakDownPrizes, getIncludedAddOnPages } from 'utils/prizes';

class NavBarContainer extends PureComponent {
  renderNavigation = (navBar, attribute, title) => {
    if (navBar[`${attribute}`]) {
      if (navBar[`${attribute}`].length > 1) {
        return <Dropdown title={title} list={navBar[`${attribute}`]} />;
      }
      return navBar[`${attribute}`].map(navItem => (
        <NavItem
          name={navItem.pageName}
          path={navItem.pageName.toLowerCase().replace(/\s/g, '-')}
          key={navItem.pageName}
        />
      ));
    }
    return '';
  };

  renderProceedsPage = navBar => {
    if (navBar.proceedsPage) {
      return (
        <NavItem
          name={navBar.proceedsPage.pageName}
          path={navBar.proceedsPage.pageName.toLowerCase().replace(/\s/g, '-')}
        />
      );
    }
    return '';
  };

  render() {
    const { toggleMobileMenuState, displayMobileMenu } = this.props;

    const {
      primaryPrizePages,
      addOnPages,
      proceedsPage,
      appearance: { programLogo } = {},
      categoryPrizePages = [],
      listPrizePages = [],
      winnersPage
    } = data.raffle;

    if (winnersPage && winnersPage.winnersMode) {
      return (
        <NavBar
          programLogo={programLogo}
          toggleMenu={toggleMobileMenuState}
          displayMobileMenu={false}
          showBuyTicketsButton={false}
        />
      );
    }

    const { grandPrizes, earlyBirdPrizes, bonusPrizes } = breakDownPrizes(primaryPrizePages);

    const morePrizes = [...bonusPrizes, ...categoryPrizePages, ...listPrizePages];

    const navBar = {
      grandPrizes,
      morePrizes,
      earlyBirdPrizes,
      addOnPages: addOnPages ? getIncludedAddOnPages(addOnPages) : [],
      proceedsPage
    };

    const grandPrizeLinks = this.renderNavigation(navBar, 'grandPrizes', 'Grand Prizes');
    const earlyBirdLinks = this.renderNavigation(navBar, 'earlyBirdPrizes', 'Early Bird');
    const morePrizeLinks = this.renderNavigation(navBar, 'morePrizes', 'More Prizes');
    const addOnLinks = this.renderNavigation(navBar, 'addOnPages', 'Add-Ons');
    const proceedsLink = this.renderProceedsPage(navBar);

    return (
      <NavBar
        programLogo={programLogo}
        toggleMenu={toggleMobileMenuState}
        displayMobileMenu={displayMobileMenu}
        grandPrizeLinks={grandPrizeLinks}
        earlyBirdLinks={earlyBirdLinks}
        morePrizeLinks={morePrizeLinks}
        addOnLinks={addOnLinks}
        proceedsLink={proceedsLink}
      />
    );
  }
}

NavBarContainer.propTypes = {
  toggleMobileMenuState: PropTypes.func.isRequired,
  displayMobileMenu: PropTypes.bool.isRequired
};

const mapStateToProps = ({ menuState }) => ({
  displayMobileMenu: menuState.displayMobileMenu
});

const mapDispatchToProps = {
  toggleMobileMenuState: () => toggleMobileMenu()
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavBarContainer);
