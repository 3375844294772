const moment = require('moment-timezone');

const stripTimeAndAppendDate = (time, date, timeZone) => {
  const stripTime = new Date(time);

  const newDate = moment(date).tz(timeZone);
  newDate.hours(stripTime.getHours());
  newDate.minutes(stripTime.getMinutes());
  newDate.seconds(stripTime.getSeconds());

  return newDate;
};

// eslint-disable-next-line import/prefer-default-export
module.exports = { stripTimeAndAppendDate };
